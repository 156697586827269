@use "~@sensible/components/theme.module.scss";

.gridItem {
  padding: 10px 40px 0 0;
}

.url {
  overflow-wrap: break-word;
}

.url > * {
  margin: 10px 0;
}