@use "~@sensible/components/theme.module.scss";

.currentLink {
  font-weight: 700;
  user-select: none;
}

.link {
  font-weight: 300;
  user-select: none;
}