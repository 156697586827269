@use '../../styles/theme.module.scss';

.container{
  padding: 6px;
}

.plus {
  margin: 0px 20px;
}

$max-height: 40px;

.badgeWrapper,
.plus,
.logo {
  display: inline-block;
  vertical-align:  middle;
}

.badgeWrapper {
  max-height: $max-height;
  
  * {
    max-height: $max-height;
    width: 100%;
  }
}
