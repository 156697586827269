@use "~@sensible/components/theme.module.scss";

.container {
  background: theme.$ocean;
  height: 100%;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.logo {
  width: 100%;
  padding: 5px;
  max-height: 100%;
  object-fit: contain;
}

.logoContainer {
  margin-bottom: 100px;
  max-height: 100%;
}

.footerLinkContainer {
  display: flex;
  align-items: flex-end;
  flex: 1;
}