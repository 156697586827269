@use "~@sensible/components/theme.module.scss";

$padding: 20px;
.container {
  width: 335px;
}

.hover {
  height: 1.4rem;
  padding-left: $padding;
}

.paper {
  padding: $padding;
  cursor: pointer;
  margin-top: 5px;
}

.value {
  font-size: 18px !important;
  font-weight: 500;
  letter-spacing: 0;
}

.label {
  height: 28px;
  align-items: flex-end;
  display: flex;
}