@use "~@sensible/components/theme.module.scss";

.container {
  background: theme.$ocean;
  height: 100%;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.logo {
  padding: 5px;
  height: 100%;
  object-fit: contain;
}

.logoContainer {
  height: 100%;
  margin-bottom: 100px;
}
