@use 'sass:map';
@use './colors.module.scss';


// Barlow
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@200;300;400;500;700&display=swap');

// Vintage
@font-face {
  font-family: 'Vintage';
  src: url('~/src/fonts/Vintage/Vintage.ttf') format('truetype'),
       url('~/src/fonts/Vintage/Vintage.woff') format('woff'),
       url('~/src/fonts/Vintage/Vintage.otf') format('opentype');
}

// Font Family
$font-primary: 'Barlow', sans-serif;
$font-secondary: 'Vintage', serif;

body {
    font-family: $font-primary;
    color: colors.$midnight
};

// Font Sizes
$font-size: (
    'display': 7.2rem,          
    'small-display': 3rem,  
    'h1': 5.4rem,             
    'h2': 2.8rem,                 
    'h3': 3.2rem,             
    'h4': 2.6rem,                
    'h5': 2rem,             
    'h6': 1.8rem,              
    'h7': 4.8rem,                 
    'body-1': 2rem,         
    'body-2': 1.6rem,          
    'body-3': 1.4rem,            
    'body-4': 1.2rem,         
    'label-1': 1.4rem,            
    'label-2': 2rem,         
    'label-3': 1.6rem,            
    'error': 1.4rem,          
);

// Line Height
$line-height: (
    'display': 8rem,         
    'small-display': 3.4rem,    
    'h1': 5.4rem,             
    'h2': 3.2rem,               
    'h3': 4rem,              
    'h4': 3rem,              
    'h5': 2.4rem,              
    'h6': 2rem,              
    'h7': 5.4rem,             
    'body-1': 2.6rem,         
    'body-2': 1.8rem,           
    'body-3': 2rem,          
    'body-4': 1.4rem,            
    'label-1': 1.8rem,         
    'label-2': 1.8rem,        
    'label-3': 2rem,         
    'error': 1.4rem,          
);

// Font Weight
$font-weight: (
    'display': 300,              // Light
    'small-display': 300,        // Extra-Light 
    'h1': 300,                   // Light
    'h2': 400,                   // Regular
    'h3': 300,                   // Light
    'h4': 400,                   // Regular
    'h5': 500,                   // Medium
    'h6': 400,                   // Regular
    'h7': 400,                   // Regular
    'body-1': 300,               // Light
    'body-2': 300,               // Light
    'body-3': 300,               // Light
    'body-4': 500,               // Medium
    'label-1': 700,              // Bold
    'label-2': 300,              // Light
    'label-3': 500,              // Medium
    'error': 400,                // Regular
);

// Font Family
$font-family: (
    'display': $font-primary,
    'small-display': $font-primary,
    'h1': $font-primary,
    'h2': $font-secondary,
    'h3': $font-primary,
    'h4': $font-primary,
    'h5': $font-primary,
    'h6': $font-secondary,
    'h7': $font-secondary,
    'body-1': $font-primary,
    'body-2': $font-primary,
    'body-3': $font-primary,
    'body-4': $font-primary,
    'label-1': $font-primary,
    'label-2': $font-primary,
    'label-3': $font-primary,
    'error': $font-primary,
);

// Letter Spacing
$letter-spacing: (
    'body-1': 0.02em,
    'body-3': 0.05em,
    'label-1': 0.05em,
    'label-3': 0.05em,
);

//Text Decoration
$text-decoration: (
    'label-2': underline
);

//Text Color - exceptions
$color: (
  'error': colors.$status-red
);


$typography-element-names: (
    'display', 
    'small-display', 
    'h1', 
    'h2',
    'h3', 
    'h4', 
    'h5', 
    'h6', 
    'h7', 
    'body-1', 
    'body-2', 
    'body-3', 
    'body-4',
    'label-1',
    'label-2',
    'label-3',
    'error',
    );

@each $name in $typography-element-names {
    %#{$name} {
        color: map.get($color, $name) !important;
        font-size: map.get($font-size, $name);
        font-family: map.get($font-family, $name);
        line-height: map.get($line-height, $name);
        font-weight: map.get($font-weight, $name);
        letter-spacing: map.get($letter-spacing, $name);
        text-decoration: map.get($text-decoration, $name);
    }
}