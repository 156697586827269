@use "~@sensible/components/theme.module.scss";

$side-panel-width: 300px;
$mobile-header-height: 100px;

.fixed {
  position: fixed;
  top: 0;
  left: 0;
}

.navigationSidePanel {
  @media screen and (max-width: 992px) {
    display: none;
    visibility: hidden;
  }
  width: $side-panel-width;
  height: 100vh;
}

.navigationMobile {
  @media screen and (min-width: 993px) {
    display: none;
    visibility: hidden;
  }
  width: 100%;
  height: $mobile-header-height;
}

.content {
  @media screen and (min-width: 993px) {
    padding-left: $side-panel-width + 40;
    padding-top: 40px;
    padding-right: 40px;
  }
  @media screen and (max-width: 992px) {
    padding-left: 40px;
    padding-top: $mobile-header-height + 40;
    padding-right: 40px;
  }
  width: 100%;
}