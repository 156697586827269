
.breadcrumb {
  display: inline-flex;
  flex-wrap: wrap;
}

.formFieldsContainer > * {
  padding-bottom: 20px;
}

.nextButtonContainer {
  padding-top: 28px;
}