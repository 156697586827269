@use "~@sensible/components/theme.module.scss";

.container {
  border: theme.$ocean solid;
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 20px;
  margin: 20px 0px;
}

.textContainer {
  flex: 1;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 14px;
}

